<template>
  <wx-open-launch-weapp id="launch-btn" username="gh_ffce7267db26" path="pages/tabbar/home/home">
    <template>
      <button
        style="width: 200px; height: 45px; text-align: center; font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px; border: none; border-radius: 4px; background-color: #07c160; color:#fff;">
        打开小程序
      </button>
    </template>
  </wx-open-launch-weapp>
</template>
<script>
import wx from 'weixin-js-sdk'
import { getH5Data } from '@/api/admin/institution'
export default {
  name: 'index',
  data() {
    return {}
  },
  methods: {
    async getData(){
      getH5Data().then(res => {
        const data = res.data
        wx.config({
          debug: true,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId,         // 必填，公众号的唯一标识，填自己的！
          timestamp: data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
          nonceStr: data.nonceStr,   // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名，见附录1
          jsApiList: [
            'wx-open-launch-weapp',
          ],
          openTagList: ["wx-open-launch-weapp"] // 跳转小程序时必填
        })
        wx.ready(function (res) {
          console.log(res,'res')
        })
        wx.error(function (res) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
          console.log(res,'error')
        });
      })
    }
  },
  created() {
    this.getData()
  },
}
</script>

<style scoped>
wx-open-launch-weapp{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
